const loadLazyLoadedVideos = () => {
	$( 'video source' ).each( function() {
		if ( !$( this ).attr( 'data-src' ) ) {
			return;
		}

		let $videoElement = $( this ).parent();

		if ( !$videoElement.length ) {
			return;
		}

		let videoPoster = $videoElement.attr( 'data-poster' );

		if ( videoPoster ) {
			$videoElement.attr( 'poster', videoPoster );
		}

		$( this ).attr( 'src', $( this ).attr( 'data-src' ) );

		$videoElement[0].load();
	} );
};

$( window ).on( 'load', function() {
	loadLazyLoadedVideos();
} );
