function ninjaFormsModals() {
	const $modalThankYou = $( '#modal-thank-you' );
	const $modalError = $( '#modal-error' );
	if ( !$modalThankYou.length ) { return; }
	const $messageSuccess = $modalThankYou.find( '.modal-message__content' );
	const $messageError = $modalError.find( '.modal-message__error-text' );

	const closeAllModals = () => {
		$( '.modal' ).modal( 'hide' ); // Close all currently open modals
	};
	const openModalSuccess = ( hash, message ) => {
		closeAllModals();
		$messageSuccess.html( message );
		$modalThankYou.modal( 'show' );
		history.replaceState( null, document.title, location.pathname + location.search + hash );
	};

	const openModalError = ( message ) => {
		$messageError.html( message );
		$modalError.modal( 'show' );
	};

	$modalThankYou.on( 'hidden.bs.modal', () => {
		history.replaceState( null, document.title, location.pathname + location.search );
		$messageSuccess.html( $messageSuccess.attr( 'data-message-default' ) );
	} );

	$modalError.on( 'hidden.bs.modal', () => {
		$messageError.html( $messageError.attr( 'data-message-default' ) );
	} );

	const handleErrorRequired = ( errors ) => {
		for ( const fieldId in errors.fields ) {
			if ( Object.prototype.hasOwnProperty.call( errors.fields, fieldId ) ) {
				const errorField = errors.fields[fieldId];
				if ( errorField.message === 'This field is required.' ) {
					return 'Please fill all required fields.';
				}
			}
		}
		return '';
	};

	const handleErrorCleanTalk = ( responseErrorMessage ) => {
		if ( responseErrorMessage.includes( 'CleanTalk' ) ) {
			return $messageError.attr( 'data-cleantalk' );
		}
		return '';
	};

	const handleErrors = ( response ) => {
		const firstErrorKey = Object.keys( response.response.errors?.fields )[0];
		let responseErrorMessage = response.response.errors?.fields[ firstErrorKey ];

		let errorMessage = handleErrorRequired( response.response.errors );

		if ( typeof responseErrorMessage === 'string' && !errorMessage ) {
			errorMessage = handleErrorCleanTalk( responseErrorMessage );
		}

		if ( errorMessage === '' ) {
			errorMessage = 'An error occurred. Please check the form.';
		}

		removeFormErrors();
		openModalError( errorMessage );
	};

	const removeFormErrors = () => {
		let forms = nfRadio.channel( 'app' ).request( 'get:forms' );

		forms.each( function( form ) {
			let formFields = form.get( 'fields' );

			if ( !formFields ) {
				return;
			}

			formFields.models.forEach( fieldModel => {
				if ( fieldModel.get( 'errors' ).length ) {
					nfRadio.channel( 'fields' ).request( 'remove:error', fieldModel.get( 'id' ), 'required-error' );
				}
			} );
		} );
	};


	$( document ).on( 'nfFormSubmitResponse', function( event, response ) {
		const formId = response.id;
		const $formCont = $( '#nf-form-' + formId + '-cont' );
		const responseData = response.response.data;

		let hash = 'thank-you';
		if ( response.response.errors.length === 0 ) {
			const classList = Array.from( $formCont[0].classList );
			const matchingClass = classList.find( ( item ) => item.startsWith( 'js-form_' ) );
			if ( matchingClass ) {
				hash += `-${matchingClass.split( '_' )[1]}`;
			}
			openModalSuccess( '#' + hash, responseData.actions.success_message );
			$formCont.find( '.nf-response-msg' ).html( '' );
		} else {
			handleErrors( response );
		}
	} );
}

export default ninjaFormsModals;
