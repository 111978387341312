import 'select2/dist/js/select2.full.min';
import 'select2/dist/css/select2.min.css';

function customSelect() {
	const $selects = $( 'select' );
	if ( !$selects.length ) { return false; }
	$selects.each( ( i, item ) => {
		const $select = $( item );
		$select.select2( {
			width: '100%',
			minimumResultsForSearch: Infinity,
			dropdownCssClass: 'select2-dropdown--filters'
		} );

		$select.on( 'select2:select', function( e ) {
			e.preventDefault();
			const event = new Event( 'change', { bubbles: true } );
			$select[0].dispatchEvent( event );
		} );
	} );
}

export default customSelect;
