/**
 * External Dependencies
 */
import 'bootstrap/js/dist/modal';


/**
 * Modules
 */
import './modules/gtm-event';
import './modules/videos-lazy-load';
import './modules/nf-auto-scroll';
import modalVideo from './modules/modal-video';
import modalYoutubeVideo from './modules/modal-video-youtube';
import modal from './modules/modal';
import ninjaFormsModals from './modules/ninja-forms-modals';
import ninjaFormsSelect from './modules/ninja-forms-select';
import textReadMore from './modules/text-read-more';
import customScrollbar from './modules/custom-scrollbar';
import customSelect from './modules/custom-select';


const admin = () => {

};

const front = () => {
	modalVideo();
	modalYoutubeVideo();
	modal();
	textReadMore();
	customScrollbar();
	ninjaFormsModals();
	ninjaFormsSelect();
	customSelect();
};

$( () => {
	const $body = $( 'body' );
	if (
		$body.hasClass( 'wp-admin' ) ||
		$body.hasClass( 'block-editor-iframe__body' )
	) {
		admin();
	} else {
		front();
	}
} );
