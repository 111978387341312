import 'select2/dist/js/select2.full.min';
import 'select2/dist/css/select2.min.css';

function ninjaFormsSelect() {
	const initializeSelect2 = function( ninjaform ) {
		const $selects = $( ninjaform.$el ).find( 'select.nf-element' );

		if ( $selects.length ) {
			$selects.each( function( i, item ) {
				const $select = $( item );

				if ( !$select.data( 'select2' ) ) {
					$select.select2( {
						width: '100%',
						minimumResultsForSearch: Infinity,
						dropdownCssClass: 'select2-dropdown--form'
					} );

					$select.on( 'change', function() {
						if ( this.value ) {
							$select.addClass( 'selected-value' );
						} else {
							$select.removeClass( 'selected-value' );
						}
					} );
				}
			} );
		}
	};

	$( document ).on( 'nfFormReady', function( e, ninjaform ) {
		initializeSelect2( ninjaform );
	} );

	$( document ).on( 'nfFormSubmitResponse', function( e, ninjaform ) {
		const $form = $( ninjaform.$el );

		setTimeout( function() {
			if ( $form.length ) {
				initializeSelect2( ninjaform );
			}
		}, 100 );
	} );

	const observer = new MutationObserver( function( mutations ) {
		mutations.forEach( function( mutation ) {
			if ( mutation.type === 'childList' ) {
				const form = mutation.target.closest( '.nf-form-cont' );
				if ( form ) {
					initializeSelect2( { $el: $( form ) } );
				}
			}
		} );
	} );

	$( document ).on( 'nfFormReady', function( e, ninjaform ) {
		const formContainer = $( ninjaform.$el )[0];
		observer.observe( formContainer, {
			childList: true,
			subtree: true,
		} );
	} );
}

export default ninjaFormsSelect;
