const nfAutoScroll = () => {
	if ( typeof Marionette !== 'undefined' ) {
		var CustomFieldController = Marionette.Object.extend( {
			initialize: function() {
				this.listenTo( nfRadio.channel( 'fields' ), 'before:submit', this.checkErrors );
				nfRadio.channel( 'submit' ).reply( 'focus:firstInvalidInput', this.focusFirstInvalidInput );
			},
			checkErrors: async function( model ) {
				const formID = model.get( 'formID' );
				const errorsObject = document.getElementById( 'nf-form-' + formID + '-cont' ).querySelector( '.nf-fail' );

				if ( !errorsObject ) {
					let formChannel = nfRadio.channel( 'form-' + formID );
					formChannel.request( 'remove:error', 'field-errors' );
				}
			},
		} );

		new CustomFieldController();
	}
};

$( document ).on( 'nfFormReady' , function() {
	nfAutoScroll();
} );
