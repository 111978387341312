import YouTubePlayer from 'youtube-player';

function modalVideoYoutube() {
	const $modalYoutubeVideoBtn = $( '.js-modal-youtube-video-btn' );

	if ( !$modalYoutubeVideoBtn.length ) { return; }

	const $modalYoutubeVideo = $( '#modal-youtube-video' );
	const $player = $modalYoutubeVideo.find( '#player' );

	window.Works24YTPlayer = YouTubePlayer( $player[0], {
		playerVars: {
			rel: 0,
			showinfo: 0,
		},
	} );

	function getYouTubeVideoID( url ) {
		const regex = /[?&]v=([^&#]*)/;
		const match = url.match( regex );
		return match ? match[1] : null;
	}

	$modalYoutubeVideoBtn.off( 'click' ).on( 'click', function() {
		const videoUrl = $( this ).data( 'video-url' );
		const videoID = getYouTubeVideoID( videoUrl );

		if ( videoID ) {
			window.Works24YTPlayer.loadVideoById( videoID );
			$modalYoutubeVideo.modal( 'show' );
			window.Works24YTPlayer.playVideo();
		} else {
			console.error( 'Invalid YouTube URL' );
		}
	} );

	$modalYoutubeVideo.on( 'hide.bs.modal', function() {
		window.Works24YTPlayer.stopVideo();
		$( window ).trigger( 'works24VideoPopupClosed' );
	} );
}

export default modalVideoYoutube;
