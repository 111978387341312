/***
 * Module for displaying all simple popups.
****/
const modal = () => {
	const $modalBtn = $( '.js-modal-btn' );
	if ( !$modalBtn.length ) { return; }

	$modalBtn.each( ( i, btn ) => {
		const $btn = $( btn );
		const $modal = $( $btn.attr( 'href' ) );

		$btn.on( 'click', function( e ) {
			e.preventDefault();
			$modal.modal( 'show' );
		} );

		$modal.on( 'show.bs.modal', function() {
			$( 'html' ).addClass( 'modal-open' );
		} );

		$modal.on( 'hidden.bs.modal', function() {
			$( 'html' ).removeClass( 'modal-open' );
		} );
	} );
};

export default modal;
