import Simplebar from 'simplebar';
import 'simplebar/dist/simplebar.css';
import ResizeObserver from 'resize-observer-polyfill';
window.ResizeObserver = ResizeObserver;

function customScrollbar( $wrapper = false ) {
	let $customScrollbars;
	if ( $wrapper ) {
		$customScrollbars = $wrapper.find( '.js-custom-scrollbar' );
	} else {
		$customScrollbars = $( '.js-custom-scrollbar' );
	}
	if ( !$customScrollbars.length ) { return false; }
	if ( $( 'body' ).hasClass( 'platform-apple' ) ) {
		return false;
	}

	setTimeout( function() {
		$customScrollbars.each( ( i, item ) => {
			const $scrollbar = $( item );
			if ( $scrollbar.attr( 'data-simplebar' ) === 'init' ) {
				return;
			}
			new Simplebar( $scrollbar[0], {
				autoHide: true
			} );
		} );
	}, 500 );
}

export default customScrollbar;
